{
    "General": {
        "Video": {
            "Titles": [
                "GIFA 2023",
                "Product Quality Message",
                "GIFA 2019"
            ],
            "NotSupported": "Your browser doesn't support the video-Tag."
        },
        "Address": {
            "Company": "Newform David S. Gower e. K.",
            "Street": "Berliner Allee 65",
            "Location": "64295 Darmstadt",
            "Country": "Germany"
        },
        "PhoneNumbers": [
            "+49 (0)6151 3975-210",
            "+49 (0)6151 3975-111"
        ],
        "ReadMore": "Read more...",
        "Download": "Download Info",
        "Dengge": "Designed & developed by"
    },
    "Navigation": {
        "Home": "Home",
        "Products": "Products",
        "News": "News",
        "Application": "Application",
        "AboutUs": "About us",
        "Privacy": "Privacy",
        "Contact": "Contact",
        "LegalNotice": "Legal Notice",
        "Back": "Back"
    },
    "Home": {
        "Document": {
            "Title": "Newform - Home"
        },
        "Header": {
            "Title": "Welcome to Newform",
            "Description": "We consult pertaining to mica lining materials as well as electric- and thermal insulation (also biodegradable) in your foundry. And deliver tear-proof slip-plane mica (muscovite or phlogopite) material for relining of your induction coil furnace once refractory material needs replacement."
        },
        "News": [
            {
                "Title": "GIFA 2023"
            },
            {
                "Title": "GIFA 2019"
            },
            {
                "Title": "GIFA 2015"
            }
        ],
        "Products": [
            {
                "Title": "Combi G"
            },
            {
                "Title": "Foil PGM"
            }
        ]
    },
    "Products": {
        "Document": {
            "Title": "Newform - Products"
        },
        "Header": {
            "Title": "Products",
            "Description": "Slip-plane Foils"
        },
        "Products": [
            {
                "Description": "Description:",
                "Application": "Application:"
            },
            {
                "Title": "Combi G",
                "Description": "flexible, ductile laminated fine mica (phlogopite) and calcium silicate fibers with a special binder asbestos tear resistant",
                "Application": "Overlay film version of the old Press refractory material for induction furnaces without transport"
            },
            {
                "Title": "Foil PGM",
                "Description": "Insulating flogopitbol ductile (high temperature resistance) and muscovite (high electrical insulation) high elasticity and high mechanical strength, fiber-reinforced, tear-resistant",
                "Application": "Overlay film version of the old Press refractory material for induction furnaces without transport"
            }
        ]
    },
    "News": {
        "Document": {
            "Title": "Newform - News"
        },
        "Header": {
            "Title": "News",
            "Description": ""
        },
        "Years": [
            {
                "Year": "Year 2023",
                "News": [
                    {
                        "Title": "",
                        "Metadata": "",
                        "Content": ""
                    }
                ]
            },
            {
                "Year": "Year 2019",
                "News": [
                    {
                        "Title": "GIFA 2019",
                        "Metadata": "June 25th, 2019, by: Newform",
                        "Content": "The GIFA team at Hall 10 F77 Judith, Alla, David, Ankit, Uwe and Jan welcome you for a vivid exchange on mica lining, refractory and microporous insulation, their implementation and services rendered. We will serve drinks, espresso and tasty Spaghetti lunch."
                    }
                ]
            },
            {
                "Year": "Year 2015",
                "News": [
                    {
                        "Title": "Mica slip plane for ferrous and non-ferrous foundries",
                        "Metadata": "August 31st, 2015, by: Newform",
                        "Content": "Effective application for coreless induction coil furnnaces Sensitive, water-cooled copper coils in induction furnaces are protected by a concrete wall (coil grout) against refractory (thermal insulation). Removal of the worn refractory material with a hammer drill is expensive and dangerous. A tear resistant mica-based layer of mica enables separation from residual (sintered) refractory mass from the concrete wall as it is pushed out and hence protects the coil, enlonges servic..."
                    },
                    {
                        "Title": "GIFA 2015",
                        "Metadata": "March 29th, 2015, by: Newform",
                        "Content": "At GIFA Newform will participate with its partners from Brazil and Spain. Meet us at Hall 10 booth F77 for a chat, espresso and pasta. Newform: Mica lining for gray iron foundries - Induction coil furnaces. Z-Tech: Al-Refractories for large Al-Furnaces AMV ALEA: Optimized charging software for melting shopFurther: Altona - steel components (high engineering and heat treatment qualification) to heavy industry"
                    }
                ]
            }
        ],
        "News": [
            {
                "Document": {
                    "Title": "Newform - Mica slip plane for ferrous and non-ferrous foundries"
                },
                "Title": "Mica slip plane for ferrous and non-ferrous foundries",
                "Content": [
                    "August 31st, 2015, by: Newform",
                    "Effective application for coreless induction coil furnnaces",
                    "Sensitive, water-cooled copper coils in induction furnaces are protected by a concrete wall (coil grout) against refractory (thermal insulation). Removal of the worn refractory material with a hammer drill is expensive and dangerous. A tear resistant mica-based layer of mica enables separation from residual (sintered) refractory mass from the concrete wall as it is pushed out and hence protects the coil, enlonges service life of the refractory in the crucible and reduces maintenance costs.",
                    "When lining induction coil furnaces, SiO2-based refractory material is inserted locally into the crucible and compacted to form the thermal insulation of Coil from smelt. The approximately 10 cm thick insulation degenerate, depending on the nature of the smelt, after some 200 applications (2-6 weeks with medium frequency Furnaces).",
                    "Removal of the old, used, mass and slag and relining of crucible furnaces with heat insulation demands caution and experience. Getting the coil grout surfaces then sufficiently smooth, so that no defects in the coil wall arise, is labor- and time consuming.",
                    "Newform provides an asbestos-free and biodegradable mica-based layer to serve as slip-plane. The insulating material consists of glas fiber reinforced mica paper laminated with heat-resistant binder to Calcium Silicate paper if required (at larger furnaces, where the wall is rather uneven).",
                    "This, generally 100cm wide and odorless \"wallpaper\" are delivered on a 25 m long roll. They are cut to required size and vertically mounted on the coil side of the crucible furnace (Fig. 1) and fixed at the top with glue (watery and halogen free) or tape. Mica side shows to the interior of the furnace (Fig. 2). The mica coating ensures good sliding between the refractory material and the coil grout.",
                    "Insulation fiber Newform Combi G is a flexible, cold formed laminate comprised of phlogopite and calcium silicate fiber with a special silicon binder. The approximately 2.4-2,5mm thick fiber for the isolation of induction furnaces tolerate temperatures up to 1,050°C, compensates surface unevenness of the, only 2 cm thick, concrete wall and thus reduces cavities.",
                    "Newform Foil PGM, is about 0,4-0.5mm thick,glass-fiber reinforcedand preferably used for smooth interior walls of the furnace to be found in smaller than 2ton furnaces usually.",
                    "Effective and health- and ecologically harmless",
                    "The asbestos-free insulation fibers and insulation foils for induction furnaces are based on muscovite or phlogopite mica (Fig. 3). Mica is a layered silicate with a high electrical resistance. It is heat resistant and in addition resistant to almost all media, such as alkalis, gases, oils and acids.",
                    "According to Hazardous Substances 91/155/EEC Regulation, mica has no health risks, both in storage and during installation and removal. Thus, no special measures have to be taken in respect of the removal of the insulation fibers and insulation films for induction furnaces. The material decomposes in nature into its individual components and can be safely disposed as construction debris and municipal waste landfills.",
                    "Newform has now expanded its product portfolio for the foundries and distributes adjacent products for the melting shop like special software for optimized charging of used materials and alloys. Itoperates in 20 countries with a network of agents and partners, who know the local language and the corresponding national markets.",
                    "Author and picture provider: David S. Gower, Newform",
                    "www.newform.de",
                    "Tel.: 06151-605210",
                    "mica-based linings are flexible and easy to handle, and have replaced asbestos inmost countries of the world paying attention to non-hazardous products to maintain the health of foundry personell.",
                    "Glasfibre reinforced, non toxic and  generally delivered on Roll (500mm or 1.000mm wide  and 10, 20 or 25 sqm per roll). They serve as:",
                    "<b>Slip-Plane</b> mica with high tensile strength",
                    "<b>Saftey-Layer</b> protecting the coil against refractory material",
                    "<b>Separating-Layer</b> gas diffusion barrier",
                    "<b>Isolation-Layer</b> electrical and thermal protection",
                    "Main products:",
                    "Newform Combi G (Mica/High-temperature paper made of CaSi),",
                    "Newform Foil PGM (Phlogopite/Muscovite mica reinforced with a glasfibre mat)"
                ]
            },
            {
                "Document": {
                    "Title": "Newform - GIFA 2015"
                },
                "Title": "GIFA 2015",
                "Content": [
                    "At GIFA Newform will participate with its partners from Brazil and Spain.<br />Meet us at Hall 10 booth F77 for a chat, espresso and pasta.<br />Newform: Mica lining for gray iron foundries - Induction coil furnaces.<br />Z-Tech: Al-Refractories for large Al-Furnaces<br />AMV ALEA: Optimized charging software for melting shop<br />Further: Altona - steel components (high engineering and heat treatment qualification) to heavy industry"
                ]
            },
            {
                "Document": {
                    "Title": "Newform - GIFA 2019"
                },
                "Title": "GIFA 2019",
                "Content": [
                    "The GIFA team at Hall 10 F77 Judith, Alla, David, Ankit, Uwe and Jan welcome you for a vivid exchange on mica lining, refractory and microporous insulation, their implementation and services rendered. We will serve drinks, espresso and tasty Spaghetti lunch.<br /><br />हॉल 10 F77 पर हमारी GIFA टीम, जिसमें शामिल हैं जूडिथ, अला, डेविड, अंकित, उवे और जॉन, निम्न विषयों पर जीवंत विनिमय के लिए आपका स्वागत करती है :-अभ्रक अस्तर, औद्योगिक दुर्दम्य सामग्री, सूक्ष्म छिद्र इन्सुलेशन, इनका कार्यान्वयन और प्रदत्त सेवाएँ।हम पेय, एस्प्रेसो और स्वादिष्ट स्पेगेटी भोजन परोसेंगे।<br /><br />Judith, Alla, David, Ankit, Uwe ve Jan ile Gifa ekibi olarak sizleri Salon 10 F77'de görmekten mutluluk duyacagiz. Sizleri mika astar, refrakter ve mikro gözenekli yalıtım malzemeleri hakkinda fikir alisverisine davet ediyoruz.Cesitli içecekler, espresso ve lezzetli spagetti öğle yemeği servisi sizlei bekliyor olacak.<br /><br />A equipe GIFA no Hall 10 F77 Judith, Alla, David, Ankit, Uwe e Jan,lhes dão as boas vindas para um animado Intercâmbio sobre placas de mica, material de isolamento refratário e micro poroso, bem como o serviço de montagem do mesmo. No stand Será servido bebidas, café e um delicioso almoço com espaguete."
                ]
            }
        ]
    },
    "Application": {
        "Document": {
            "Title": "Newform - Application"
        },
        "Buttons": [
            "Handling Instructions",
            "Editorial"
        ],
        "Header": {
            "Title": "Application",
            "Description": ""
        },
        "Products": [
            {
                "Title": "Newform Combi G",
                "Description": "is an asbestos-free, biologically dissolvable insulation material developed with special consideration to insulation technique of induction coil furnaces. Its main function: a protection-layer for the induction coil. See PDF."
            }
        ]
    },
    "AboutUs": {
        "Document": {
            "Title": "Newform - About us"
        },
        "Header": {
            "Title": "About us",
            "Description": ""
        },
        "Title": "Newform wins new major customers for its mica films Films",
        "Description": [
            "The specialized supplier of multi-layer films for induction furnaces is Gießereielieferant of large companies in Germany and beyond.",
            "The overlay transparencies of Newform support in a very effective way to increase the melting process and the protection of the induction coil from the refractory material. In addition, they reinforce an excellent gas diffusion barrier as well as for electrical thermal shield. Asbestos-free ceramic and classified the overlay films according to EC directive is considered as safe.",
            "Even with its international expansion Newform has come a long way. By cooperating with agents and dealers \"on the spot\" has been opened a lucrative access to Scandinavian and Eastern European foundry industry.",
            "Countries such as Kazakhstan and Indonesia expand more recently the clientele of the Darmstadt-based company in the international market.",
            "The Akqusitionserfolge also reflect positively in the sales. From May 2010 to April 2011 Newform increased its sales over the same period last year by 80 percent. The Worldwide Crisis of 2008/2009 Newform has now been overcome.",
            "The next steps are to GIFA Latin America, Spain and Portugal. that seek suitable partners Newform"
        ],
        "Philosophy": {
            "Title": "Philosophy",
            "Quote": "“We provide highly qualified service, combined with innovative products for daily requirements of our customers in the foundry industry.”",
            "Source": "- David S. Gower | August 23rd, 2013"
        },
        "Team": {
            "Title": "Meet The Team",
            "Member": [
                {
                    "Name": "David S. Gower",
                    "Description": "CEO"
                },
                {
                    "Name": "David Romain",
                    "Description": "Business Developer International"
                }
            ]
        }
    },
    "Contact": {
        "Document": {
            "Title": "Newform - Contact"
        },
        "Header": {
            "Title": "Contact"
        },
        "Title": "Feel free to contact us",
        "Description": "Would you like to receive more information about our offers and services, please send us your queries.",
        "ContactInfo": "Contact Info",
        "Form": {
            "Name": "Name*",
            "Mail": "E-Mail*",
            "Address": "Address",
            "Phone": "Tel./Mobile*",
            "Message": "Message*",
            "Submit": "Submit",
            "Validation": {
                "Name": {
                    "Required": "Name is required."
                },
                "Mail": {
                    "Required": "E-Mail is required.",
                    "Valid": "E-Mail is invalid."
                },
                "Message": {
                    "Required": "Message is required."
                },
                "Phone": {
                    "Required": "Tel./Mobile is required.",
                    "Valid": "Tel./Mobile is invalid."
                }
            },
            "Response": {
                "Success": [
                    "The request was sent successfully!",
                    "We will contact you shortly."
                ],
                "Fail": [
                    "An error occurred!",
                    "Try again later or contact us via e-mail."
                ],
                "Ok": "Ok"
            }
        }
    },
    "Footer": {
        "Contact": {
            "Title": "Contact"
        },
        "Social": {
            "Title": "Social media:",
            "Links": [
                "LinkedIn"
            ]
        },
        "Newform": {
            "Title": "Newform"
        },
        "Affiliates": {
            "Title": "Affiliates"
        }
    },
    "Privacy": {
        "Document": {
            "Title": "Newform - Privacy"
        },
        "Header": {
            "Title": "Privacy Policy",
            "Description": "This privacy policy informs you about the nature, scope and purpose of the processing of personal data (hereinafter referred to as \"data\") on this website. With regard to the terms used, such as \"personal data\" or their \"processing\", we refer to the definitions in Article 4 of the General Data Protection Regulation (GDPR)."
        },
        "Responsibility": {
            "Title": "Responsible"
        },
        "Datatypes": [
            "Types of Data Being Processed",
            "Contact details (e.g. e-mail, name)"
        ],
        "Purpose": {
            "Title": "Purpose of Data Processing",
            "Descriptions": [
                "Newform David S. Gower processes your data in compliance with the General Data Protection Regulation (GDPR) and the Federal Data Protection Act (BDSG).",
                "The recorded data will only be used for these purposes and will not be passed on to data collectors or others."
            ],
            "Purposes": [
                "Contacting and Contact Management",
                "When contacting Newform David S. Gower (via contact form, telephone or e-mail), the user's details are processed for the purpose of handling the contact request and its processing pursuant to Art. 6 (1) lit. b) GDPR. When contacting Newform David S. Gower via the contact form, a automatic generated e-mail will be send to the contact address. Newform David S. Gower reserves the right to contact the user in his preferred way. A revocation of your already granted consent is possible at any time. An informal message by e-mail is sufficient for the revocation. The legality of the data processing operations carried out until the revocation remains unaffected by the revocation."
            ]
        },
        "Duration": {
            "Title": "Duration of Data Storage",
            "Description": "Your data will be deleted as soon as they are no longer required for the processing of the above-mentioned purposes. Newform David S. Gower stores your data if we are legally obligated to do so. These obligations are derived from the HGB and AO, among others."
        },
        "ThirdParties": {
            "Title": "Data Transmission to Third Parties",
            "Description": "The data Newform David S. Gower collects will not be disclosed to third parties."
        },
        "ThirdCountries": {
            "Title": "Data Transmission to Third Countries",
            "Description": "The data Newform David S. Gower collects will not be passed on to a service provider outside the EU/EEA area."
        },
        "Complaints": {
            "Title": "Right of Appeal",
            "Description": [
                "You have the right to contact the above-mentioned data protection officer  or a data protection supervisory authority with a complaint, e.g. the responsible supervisory authority in the federal state of your residence or the authority responsible for us as the responsible body. A list of supervisory authorities (for the non-public sector) with address can be found ",
                "here."
            ],
            "Rights": [
                "Your Data Protection Rights",
                "You can exercise the following rights at any time using the contact details provided by our data protection officer:",
                "Information about your data stored by Newform David S. Gower and its processing (Art. 15 GDPR),",
                "Correction of incorrect personal data  (Art. 16 GDPR),",
                "Deletion of your data stored by Newform David S. Gower  (Art. 17 GDPR),",
                "Restriction of data processing if Newform David S. Gower is not yet allowed to delete your data due to legal obligations (Art. 18 GDPR),",
                "Objection to the processing of your data by Newform David S. Gower (Art. 21 GDPR) and data portability, provided that you have consented to the data processing or have concluded a contract with us (Art. 20 GDPR).",
                "If you have given Newform David S. Gower consent, you can revoke this at any time with effect for the future."
            ]
        },
        "Changes": {
            "Title": "Changes to the Privacy Policy",
            "Description": "Newform David S. Gower reserves the right to amend the privacy policy in order to adapt it to changes in the legal situation or in the event of changes to the service and data processing. In case of doubt, we therefore ask you to check the privacy policy again before you transmit personal data to us."
        },
        "Time": "August 2021"
    },
    "LegalNotice": {
        "Document": {
            "Title": "Newform - Legal Notice"
        },
        "Header": {
            "Title": "Legal Notice"
        },
        "ResponsibilityTitle": {
            "Inhaber": "Owner:",
            "Verantwortlich": "Responsible regarding Teleservices Act:",
            "Amtsgericht": "District Court:",
            "VATNumber": "VAT-ID-Nr.:",
            "Telefon": "Phone:",
            "Telefax": "Fax:"
        },
        "Responsibility": {
            "Title": "Newform David S. Gower e. K.",
            "Inhaber": "David S. Gower",
            "Verantwortlich": "David S. Gower",
            "Amtsgericht": "Amtsgericht Darmstadt, HRA 6258",
            "VATNumber": "DE 111512978",
            "Telefon": "+49 (0)6151 3975-210",
            "Telefax": "+49 (0)6151 3975-111"
        },
        "Disclaimer": {
            "Title": "Disclaimer:",
            "Inhalt": [
                "Newform David S. Gower makes every reasonable effort to provide on this website is accurate and complete information available. Responsible or liable for the information, especially as to the completeness, accuracy and timeliness, but it can not be assumed.",
                "Any liability for damages incurred directly or indirectly from the use of this website is excluded, unless they are based on intent or gross negligence of the Newform David S. Gower or its agents.",
                "The Newform David S. Gower website contains links to other websites. Newform David S. Gower is not responsible for the content of these other websites and dissociates itself expressly from it. Newform David S. Gower reserves the right to change or amend without prior notice the information provided."
            ]
        }
    }
}